import { Box, Container, Divider, Grid, List, ListItem, ListItemIcon, ListItemText, makeStyles, Paper, Switch, Typography } from '@material-ui/core'
import { Done } from '@material-ui/icons'
import BaseLayout from 'components/BaseLayout'
import Footer from 'components/Footer'
import Header from 'components/Header'
import React, { useState } from 'react'

const products = [{
  "name": "Budgetocity Free",
  "prices": {
    "monthly": {
      "id": "price_1GqUFrIxpXIBFFswEnhe8m1B",
      "price": 0
    },
    "yearly": {
      "id": "price_1GsxnaIxpXIBFFsw5WWRATpv",
      "price": 0
    }
  },
  "description": "Budgetocity Free",
  "features": [
    "Create a Plan",
    "Build a Budget",
    "Track Spending",
    "Customize Categories",
//    "1 Profile",
    "Supported by Ads"
  ],
  "button_text": "Always Free"
},{
  "name": "Budgetocity Premium",
  "prices": {
    "monthly": {
      "id": "price_HMJVlanqHcjWV9",
      "price": 499
    },
    "yearly": {
      "id": "price_1Gt30NIxpXIBFFsw3li2Gta3",
      "price": 5499
    }
  },
  "description": "Budgetocity with Premium Features",
  "features": [
    "Create Plan",
    "Build a Budget",
    "Track Spending",
    "Customize Categories",
    "Create Savings Plans",
//    "Create a Debt Payoff",
//    "Create Unlimited Accounts",
    "Advanced Transaction Search",
    "Tag/Organize Transactions",
//    "1 Profile",
    "No Ads"
  ],
  "button_text": "Get Started"
},{
  "name": "Budgetocity Ad Free",
  "prices": {
    "monthly": {
      "id": "price_HMJUXDt4VEwEk0",
      "price": 99
    },
    "yearly": {
      "id": "price_1GyiH1IxpXIBFFswdUWmzzJn",
      "price": 999
    }
  },
  "description": "Budgetocity without Ads",
  "features": [
    "Create Plan",
    "Build a Budget",
    "Track Spending",
    "Customize Categories",
//    "1 Profile",
    "No Ads"
  ],
  "button_text": "Get Started"
}]

const useStyles = makeStyles({
  "Budgetocity-Free": {
  },
  "Budgetocity-Premium": {
    backgroundColor: "#000",
  },
  "Budgetocity-Ad-Free": {
  },
  "checkmark": {
    color: "#84C07E"
  },
  "divider": {
    borderTop: "2px solid #84C07E"
  }
})

export default () => {
  const classes = useStyles()
  const [showYearlyPrices, setShowYearlyPrices] = useState(false)

  const price = (product) => {
    if (product.prices.monthly.price === 0) {
      return "Always Free"
    }
    
    if (showYearlyPrices) {
      return `$ ${(product.prices.yearly.price/100.0).toFixed(2)} / year`
    } else {
      return `$ ${(product.prices.monthly.price/100).toFixed(2)} / month`
    }
  }
    
  return (
    <BaseLayout title={"Budgetocity™ - Pricing that fits your budget"}>
      <Header />

      <Container maxWidth="md">
        <Box display="flex" flexDirection="column" alignItems="center" mb={3}>
          <Typography variant="h3">
            Pricing that fits your budget
          </Typography>
        </Box>
        
        <Box px={1} pb={2}>
          <Switch checked={showYearlyPrices} color="primary" onClick={() => setShowYearlyPrices(!showYearlyPrices)} /> Priced Yearly
        </Box>

        <Box mb={4}>
          <Grid container spacing={4} alignItems="center">
            {products.map((product, i) => (
              <Grid item xs={12} md={4} key={`product${i}`}>
                <Paper className={classes[product.name.replace(/ /g, "-")]}>
                  <Box p={2} m={1}>
                    <Box py={1} display="flex" justifyContent="center">
                      <Typography variant="h5">{product.name}</Typography>
                    </Box>
                    <Divider className={classes.divider}/>
                    <Box py={2} display="flex" justifyContent="center">
                      <Typography variant="h6">{price(product)}</Typography>
                    </Box>
                    <Box display="flex" justifyContent="center">
                      <List dense>
                        {product.features.map(feature => (
                          <ListItem>
                            <ListItemIcon className={classes.checkmark}><Done fontSize="small"/></ListItemIcon>
                            <ListItemText primary={feature} />
                          </ListItem>
                        ))}
                      </List>
                      </Box>
                  </Box>
                </Paper>        
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>

      <Footer/>

    </BaseLayout>
  )
}